$(document).foundation();
//Клонирование html контента из одного блока в другой
var cloneHtml = function(fromSelector, toSelector){
    $(toSelector).html($(fromSelector).html());
}

//PopUp текст
function popupText(elem){
	var header = elem.data("header");
	var description = elem.data("descr");
	var btntext = elem.data("btn");
	var id = "#"+elem.data("popup");

	$(id).find('.h3').html(header);
	$(id).find('.form-title').val(header);
	$(id).find('.descr').html(description);
	$(id).find('input[type=submit]').val(btntext);
}

//Клонирование объединение соц иконок иконками соц сетей для мобильников
var cloneIconsInFooter = function(){
    var socialIcons = $("footer .social .icons").html();
    var messagersIcons = $("footer .messagers .icons").html();
    $(".social-plus-messagers .icons").html(socialIcons+messagersIcons);
}

// Закрытие всплывающих окон
var closePopUp = function(popupId){
    var animIn = $(popupId).data("anim-in");
    var animOut = $(popupId).data("anim-out");
    var overlay = $(popupId).parents('.reveal-overlay');
    $(popupId).removeClass(animIn).addClass(animOut);
    $(overlay).removeClass('animated fadeIn fast').addClass('fadeOut animated fast');
    setTimeout(function(){
        $(popupId).foundation('close');
    },500);
}

// Анимация при доскроле
var animationOnScroll = function(){
    $('.animated').each(function(){
        var imagePos = $(this).offset().top;
        var topOfWindow = $(window).scrollTop();
        var windowHeight = $(window).height();
        if($(this).data("anim-begin")){
            var heightBegin = $(this).data("anim-begin");
        }
        else{
            heightBegin = 0.8;
        }
        var beginView = windowHeight*heightBegin;
        var classAnimate = $(this).data("anim");
        if (imagePos < topOfWindow+beginView) {
            $(this).addClass(classAnimate+' done');
        }
    });
}

$(document).ready(function () {

    var curUrl = $(location).attr('href');
	$("input.curURL").val(decodeURI(curUrl));

    // Клонируем пункты главного меню для мобильной версии
    cloneHtml('header nav ul','.mobile-nav ul');

    // Клонируем иконки в футтере для мобильнойв версии
    cloneIconsInFooter();

    // Вызов мобильного меню через клик по бургеру
    $('.mobile-menu-icon').click(function () { 
        if ($('header').hasClass('open-mobile')){
            $('header').toggleClass('open-mobile');
            $('body').removeClass('no-scroll');
            $('.fly').removeClass('hiden');
        }
        else{
            $('body').addClass('no-scroll');
            $('.fly').addClass('hiden');
            $('header').toggleClass('open-mobile');
        }
    });

    // goto #
    $(document).on('click', 'a[href^=\\#]', function () {      
        var obj = $($.attr(this,'href'));   
    $('html, body').stop().animate( { scrollTop: obj.offset().top }, 500 );
    return false;
    });

    // Вызов с анимацией popUp при клике по ссылке с data-popup
    $('[data-popup]').on("click",function(){
        popupText($(this));
        var popupId = '#'+$(this).data("popup");
        var animIn = $(popupId).data("anim-in");
        var animOut = $(popupId).data("anim-out");
        $(popupId).parents('.reveal-overlay').removeClass('animated fadeOut fast').addClass('animated fadeIn fast');
        $(popupId).foundation('open').removeClass(animOut).addClass(animIn);
    });

    // Закрытие с анимацией popUp при клике по оверлею
    $('.reveal-overlay').on("click",function(e){
        if (e.target == this) {
            var popupId = '#'+$(this).find('.popup').attr('id');
            $(popupId+" form").trigger("reset");
            closePopUp(popupId);
        }
    });

    // Закрытие popUp при клике по крестику
    $('.popup .close').on('click',function(){
        var popupId = '#'+$(this).parents('.popup').attr('id');
        $(popupId+" form").trigger("reset");
        closePopUp(popupId);
    });

    // Текст внутри полей для телефонов
    $('input[type="tel"], input.phone, input#phone').on('focus', function() {
		if ($(this).val()=='' || $(this).val()==' ') $(this).val("+380");
	});
	
	$('input[type="tel"], input.phone, input#phone').on('blur', function() {
		var inputVal = $(this).val();
		if (inputVal=='+380' || inputVal.length <=3 ) $(this).val(""); 
    });
    
    //Анимации при доскролле
    animationOnScroll();

});


$(window).scroll(function() {
    // Анимация при доскролле
    animationOnScroll();

    // Плавающая кнопка остается перед футером
    var footerHeight = $('footer').outerHeight()+20;
    if($(window).scrollTop() + $(window).height() > $(document).height() - footerHeight) {
        var bottom = ($(window).scrollTop() + $(window).height()) - ($(document).height() - footerHeight);
        $('.fly').css('bottom' , bottom);
    }else{
        $('.fly').removeAttr( 'style' );
    }
 });